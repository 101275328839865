.pagination {
  position: absolute;
  top:50%;
  left: 0;
  transform: translateY(-50%);
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  z-index: 2;
  .previous-page-button,.next-page-button{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 60px !important;
    height: 100px !important;
    pointer-events: auto;
    border: 0;
    > .anticon{
      font-size: 56px !important;
    }
  }

  .ant-btn.ant-btn-background-ghost {
    color: #9dbfffad;
  }
  &.in-sharing{
    top: 0;
    right:0;
    left: auto;
    width: 264px;
    flex-direction: column;
    transform: none;
    height: 100%;
    align-items: center;
    .previous-page-button,.next-page-button{
      .anticon {
        transform: rotate(90deg);
      }
    }

  }
}