.video-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  margin-left: 2px;
}
.vc-button {
  font-size: 15px;
  background-color: #9dbfffad;
  color: #fff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 9px;
  width: 42px !important;
  height: 40px !important;
  > *{
    font-size: 18px !important;
  }
  &.active {
    color: #00cc64;
  }
 
}
.vc-dropdown-button {
  width: 70px;
  display: flex;
  justify-content: center;
  margin-right:min(30px,1vw);
  > .ant-btn {
    font-size: 20px;
    border-radius: 14px;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 13px;
    margin-left: 18px;
  }
  > .ant-btn[disabled]{
    background: transparent;
    color: #999;
  }
  > .ant-dropdown-trigger {
    margin-right: 0;
    margin-left: 4px;
  }
}

.ant-space-compact-block {
  display: flex;
  width: 90px;
}

.ant-btn-primary {
  // background: #9dbfff;
  border: none;
  border-radius: 15px;
}

.ant-btn-compact-item.ant-btn-primary:not([disabled])+.ant-btn-compact-item.ant-btn-primary:not([disabled]):after {
  width: 0px;
}

.ant-btn-icon-only.ant-btn-compact-item {
  margin: 0 3px;
}

.ant-btn-icon-only.ant-btn-lg {
  border-radius: 14px;
  margin-right: 19px;
}

.vc-dropdown-menu {
  border: 1px solid #747487;
  background: rgba(0, 0, 0, 0.9) !important;
  .ant-dropdown-menu-item {
    padding: 10px 30px;
    color: #ccc;
    position: relative;
    .anticon-check {
      position: absolute;
      left: 10px;
      top: 15px;
    }
    &:hover {
      color: #40a9ff;
    }
  }
  .ant-dropdown-menu-item-group-title {
    color: #fff;
    font-weight: 700;
  }
}