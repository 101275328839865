.slide-in {
    animation: slideIn 0.2s forwards;
}

.slide-out {
    animation: slideOut 0.2s forwards;
}

@keyframes slideIn {
    from {
        transform: translateX(150%); // Bắt đầu từ ngoài màn hình
    }

    to {
        transform: translateX(0); // Kết thúc tại vị trí ban đầu
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0); // Bắt đầu tại vị trí ban đầu
    }

    to {
        transform: translateX(150%); // Kết thúc ra ngoài màn hình
    }
}